<template>
  <div id="reportTable">
    <div class="title">
      <h3 style="display: none;">{{ data.full_name }} {{ title }}</h3>
    </div>


    <!-- Reports Table -->
    <table>
      <thead v-bind:style="{ 'background-color':  reportColour}" v-if="home != true">
        <tr>
          <template>
            <th>
              No
            </th>
            <th v-if="this.home === false">
              Country
            </th>
            <th>
              Player(s)
            </th>
            <th>
              Entry Status
            </th>
            <th v-if="this.home === false">
              Exempt Rank
            </th>
            <th v-if="config.VUE_APP_PLAYER_ICON === 'Y'"></th>
          </template>
        </tr>
      </thead>
      <tbody>

      <template >
        <template v-for="(tabledata, index) in Eds">
          <tr @click.stop="rowClicked(index)" :key="index">
            <td
              :class="{homeEntriesPos: home === true}"
              class="posnum"
              v-if="
                tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
              "
            >
              {{ tabledata.serial_no }}
            </td>
            <td colspan="11" class="catD" v-if="tabledata.playerno === 'Cat'" :class="{catSection: home === true}">
              {{ tabledata.exempt_desc }}
            </td>
            <td colspan="11" class="cut" v-if="tabledata.playerno === 'EOF'">
              {{ tabledata.exempt_desc }}
            </td>
            <template v-if="home === false">
              <td
                class="mobHide"
                v-if="
                  tabledata.pos !== 'CUT' &&
                    tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF'
                "
              >
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                  {{ tabledata.represents }}
                </span>
              </td>
            </template>
            <td
              v-if="
                tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
              "
            >
              <a href="#" :class="{ homeEntriesName: home === true} " class="nameLink">
                {{ tabledata.playing_name }}
                <span v-if="tabledata.pro_ind == 'Am'">(a)</span> 
              </a>
            </td>
            <td
              v-if="
                tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
              "
              :class="{ homeEntriesStatus: home === true}"
            >
              {{ tabledata.status_text }}
            </td>
            <template v-if="home === false">
              <td
                v-if="
                  tabledata.playerno !== 'Cat' && tabledata.playerno !== 'EOF'
                "
              >
                {{ tabledata.exempt_rank }}
              </td>
            </template>
            <td
              v-if="
                tabledata.playerno !== 'Cat' &&
                  tabledata.playerno !== 'EOF' &&
                  config.VUE_APP_PLAYER_ICON === 'Y'
              "
            >
              <a :href="'/playerprofile/' + tabledata.playerno">
                <font-awesome-icon
                  style="color:#f2af15!important;"
                  id="user"
                  class="icon"
                  :icon="['fa', 'user']"
                />
              </a>
            </td>
          </tr>
        </template>
      </template>

      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import ads from '@/js/ads.js'

export default {
  name: "reportTable",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "season",
    "code",
    "tour",
    "team",
    "config",
    "match",
  ],
  components: { 

  },
  data() {
    return {
      compare: [],
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
      win: "win",
      ads:ads,
      reportColour: process.env.VUE_APP_REPORT_MAIN_COLOUR,
      windowWidth: window.innerWidth,
      text: ''
    };
  },
  methods: {
    myFunction: function() {
      
      // let w = window.outerWidth;
      // let txt = "Window size: width=" + w + ", height=" + h;
      let h = window.outerWidth;
      let txt = h;
      this.text = txt;
    },
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" && this.clickedRow !== index)
        this.clickedRow = index;
      else this.clickedRow = -1;
    },
    playerCard: function(event) {
      var cardID = event.target.getAttribute("title");
      return (
        (this.cardID = cardID),
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              this.tour +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.code +
              "-cards-" +
              this.cardID +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then((response) => (this.playerCardData = response.data))
      );
    },
  },
  computed: {
    tablePick: function(picked) {
      if (this.title.includes("Tournament")) picked = "Entries";
      else if (this.title.includes("Draw")) picked = "Draw";
      else if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (this.title.includes("Live")) picked = "Live";
      else if (
        this.title.includes("Final") ||
        this.title.includes("Statistics")
      )
        picked = "Final";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    pftroundsPlayed() {
      return parseInt(this.data.pft_round);
    },
    Eds(reportData) {
      // const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      console.log("windowWidth")
      console.log(windowWidth)

      if (this.home === false) {
        if (this.title.includes("Tournament"))
          reportData = this.data.entries.entries_entry;
        else if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry;
        else if (
          (this.title.includes("Scores") && this.team === "N") ||
          (this.title.includes("Scoreboard") && this.team === "N") ||
          (this.title.includes("Live") && this.team === "N")
        )
          reportData = this.data.scores.scores_entry;
        else if (
          (this.title.includes("Scores") && this.team === "Y") ||
          (this.title.includes("Scoreboard") && this.team === "Y") ||
          (this.title.includes("Live") && this.team === "Y")
        )
          reportData = this.data.team_scores.team_scores_entry;
        else if (
          this.title.includes("Final") ||
          this.title.includes("Statistics")
        ) {
          reportData = this.data.result.result_entry;
          // var setOne = allData.slice(0, 5);
          // var setTwo = allData.slice(5, 25);
          // var setThree = allData.slice(25);
          // var addOne = this.ads[0];
          // var addTwo = "Test Two";
          // let comone = setOne.concat(addOne);
          // let comtwo = setTwo.concat(addTwo);
          // let comthree = comone.concat(comtwo);
          // reportData = comthree.concat(setThree);
        }
        return reportData;
      } else {
        if (process.env.VUE_APP_MOB_WIDGET == 'Y') {
            if (this.title === "Tournament Entries")
              reportData = this.data.entries.entries_entry.slice(0, 5);
            else if (this.title.includes("Draw"))
              reportData = this.data.draw.draw_entry.slice(0, 10);
            else if (
              this.title.includes("Scores") ||
              this.title.includes("Scoreboard")
            )
              reportData = this.data.scores.scores_entry.slice(0, 5);
            else if (
              this.title.includes("Final") ||
              this.title.includes("Statistics")
            )
              reportData = this.data.result.result_entry.slice(0, 5);
            return reportData;
        } else {
            if (this.title === "Tournament Entries")
              if  (this.text == 1400 || this.text > 1401) {
                reportData = this.data.entries.entries_entry.slice(0, 10); 
              } else if(this.text < 1400  && this.text > 1199) {
                reportData = this.data.entries.entries_entry.slice(0, 6);
              } else if (this.text < 990){
                reportData = this.data.entries.entries_entry.slice(0, 10);
              }
            else if (this.title.includes("Draw"))
              reportData = this.data.draw.draw_entry.slice(0, 10);
            else if (
              this.title.includes("Scores") ||
              this.title.includes("Scoreboard")
            )
              if (windowWidth < '400' && windowWidth > '311') {
                console.log('400 - 311')
                reportData = this.data.scores.scores_entry.slice(0, 7); 
              } else if(windowWidth < '311') {
                console.log(' < 400')
                reportData = this.data.scores.scores_entry.slice(0, 5);
              } else {
                reportData = this.data.scores.scores_entry.slice(0, 10);
              }
            else if (
              this.title.includes("Final") ||
              this.title.includes("Statistics")
            )
              if (windowWidth < '400' && windowWidth > '311') {
                console.log('400 - 311')
                reportData = this.data.result.result_entry.slice(0, 7); 
              } else if(windowWidth < '311') {
                console.log(' < 400')
                reportData = this.data.result.result_entry.slice(0, 5);
              } else {
                reportData = this.data.result.result_entry.slice(0, 10);
              }
            return reportData;
          }
        }
    },
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
      window.addEventListener('resize', this.Eds);
      window.addEventListener('resize', this.windowWidth);
      console.log("--" + this.windowWidth)
    },
    immediate: true,
    deep: true,
  },
  created() {
    this.myFunction();
    window.addEventListener('resize', this.myFunction);
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
a.nameLink{
  color: #000 !important;
}
a.nameLink:hover{
  color: #000 !important;
}
.catSection {
  padding-top: 16px!important;
  text-align: center!important;
}
.homeEntriesPos {
  margin-top: 8px!important;
}
.homeEntriesStatus {
  vertical-align: middle;
  text-align: right !important;
  padding-right: 20px !important;
}
a.homeEntriesName {
  margin-top: 0px!important;
}
span.multiCourseSpanTeam {
  margin-top: 10px;
  float: right;
}
span.multiCourseSpan {
  float: right;
  margin-top: -17px;
}
span.multiCourseSpanTeam.B {
  color: #333333;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.Y {
  color: #d8db22;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.C {
  color: #1E90FF;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.R {
  color: #BB0000;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.G {
  color: #3c9933;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpanTeam.O {
  color: #e07020;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: 17px;
}
span.multiCourseSpan.B {
  color: #333333;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
span.multiCourseSpan.Y {
  color: #d8db22;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
span.multiCourseSpan.C {
  color: #1E90FF;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
span.multiCourseSpan.R {
  color: #BB0000;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
span.multiCourseSpan.G {
  color: #3c9933;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
span.multiCourseSpan.O {
  color: #e07020;
  font-size: 43px;
  line-height: 0;
  vertical-align: bottom !important;
  margin-top: -7px;
}
.B {
  color: #333333;
}
.Y {
  color: #d8db22;
}
.C {
  color: #1E90FF;
}
.R {
  color: #BB0000;
}
.G {
  color: #3c9933;
}
.O {
  color: #e07020;
}
td.posDown {
  position: relative;
  padding-top: 20px;
}
/* td.posDownWinnings {
  margin-top: 10px;
  position: absolute;
} */
span.posDown {
  position: relative;
  top: 8px;
}
td.ParBlack{
  position: relative;
  padding-top: 20px;
}
span.ParBlack{
  position: relative;
  top: 8px;
}
::v-deep span.down.posDown {
  position: relative;
  top: 8px;
}
td.down.posDown {
  position: relative;
  padding-top: 20px;
}
::v-deep span.up.posDown {
  position: relative;
  top: 8px;
}
td.up.posDown {
  position: relative;
  padding-top: 20px;
}
.scorePos {
  margin-left: 0px!important;
}
span.FlagScores {
  margin-top: 6px;
  display: block;
}
::v-deep button#__BVID__32__BV_toggle_ {
  line-height: 35px;
  padding-bottom: 0;
  height: 40px;
}
td.drawName > a {
  margin-top: 1px;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px!important;
  padding-top: 7px!important;
  padding-bottom: 22px!important;
}
.title {
  text-align: center;
  background-color: #00433e;
  color: white;
}
.title > h3 {
  padding-top: 8px;
  margin-bottom: 0px;
  font-size: 24px;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  position: sticky;
  top: 0;
  /* background-color: #00433e; */
  vertical-align: bottom;
  border-bottom: 0px solid #ddd;
  font-weight: bold;
  color: white;
  font-size: 13px;
  text-transform: capitalize;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  /* background: #00433e; */
  color: #ffffff !important;
  text-align: left;
  padding-bottom: 12px;
  padding-top: 12px;
  z-index: 2;
}

.win {
  background-color: #02b2dd !important;
  color: black;
}
.catD {
  color: #7d7d7d;
  font-weight: 300;
}
.drawName {
  font-size: 14px !important;
}
table {
  width: 100%;
}
tr {
  border-bottom: 1px solid #00000033!important;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: left;
}
td:last-child {
  padding-right: 0!important;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: left;
}
td:nth-child(3) {
  text-align: left;
}
.odd {
  background-color: white !important;
}
.even {
  background-color: #dfe1e6 !important;
}
tr:nth-child(even) {
  background-color: #fff;
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  color: black;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
}
::v-deep td > a {
  /* margin-top: 6px; */
  display: block;
}
td {
  padding: 12px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0px solid #ddd;
  border-right: none !important;
  cursor: pointer;
  text-align: left;
  padding-left: 0px;
  font-size: 14px;
  font-weight: 600;
}

td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}
::v-deep a:hover{
      color: #00b588!important;
      text-decoration: none; 
}
@media only screen and (max-width: 768px) {
  td {
    /* font-size: 12px !important; */
  }
  th {
    font-size: 13px;
  }
  .mobHide {
    display: none;
  }
  #reportCard {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>