<template>
  <div class="home">
        <b-row>
          <b-col lg='12' md='12' cols='12' >
              <div class="homeReport-list HomehomeReport-list homeHeight" :style="'height:' + NewWidgetHeight" :class="{mobWidgetPad: mobWidget == 'Y'}">
                <b-tabs content-class="test" >
                  <b-tab title="This Week" id="onetab" title-item-class="tabsStyle tabone" active>
                    <ReportHomeTab
                      :Year="season"
                      :code="course"
                      :course_code="course_code"
                      :reportURL="currentReport"
                      :currentReport="reportTitle"
                      :title="currentTitle"
                      :config="config"
                      :home="home"
                      :tournDates="tournDates"
                      :updateSize="updateSize"
                      :widgetHeight="widgetHeight"
                      :reports="reports"
                    />
                  </b-tab>
                  <b-tab title="Upcoming" title-item-class="tabsStyle tabtwo" classActive>
                    <UpcomingReportSelect :season="season"/>
                  </b-tab>
                  <b-tab title="Results" title-item-class="tabsStyle tabthree">
                    <ResultsReportSelect :season="season"/>
                  </b-tab>
                </b-tabs>
              </div>
          </b-col>
        </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import ReportHomeTab from "@/components/reportHometab.vue";
import UpcomingReportSelect from "@/components/UpcomingReportSelect.vue";
import ResultsReportSelect from "@/components/ResultsReportSelect.vue";
import axios from "axios";
export default {
  name: "home", 
  props: ['config'],
  components: { 
    UpcomingReportSelect,
    ResultsReportSelect,
    ReportHomeTab,
  }, // Everything below on every /view/<Page Name>.vue for meta info and all ACF data
  data: function() {
    return {
      title: [], //Page Title
      description: [], //Meta Description
      homepage: true,
      home: true,
      wphome: [],
      inter: "5",
      pro: "44",
      latestsm: "29",
      latestFull: "8",
      featHome: "true",
      reports: [],
      course: [],
      season: [],
      course_code: [],
      tm_params:[],
      mobWidget: process.env.VUE_APP_MOB_WIDGET,
      text: '',
      NewWidgetHeight: '',
      activeTab: '',
    };
  },
  methods: {
    reportListText: function() {
      console.log('InFunction')
      if (Array.isArray(this.reports)) {
        console.log('not array')
        this.activeTab = false
        this.classActive = 'active'
      } else {
        this.reports.forEach((value) => {
          console.log('is array')
          console.log('In For Each Function')
          if (value.report_title.includes('Score')) {
            this.activeTab = true
            this.classActive = 'active'
            console.log('TRUE')
          } else {
            this.activeTab = false
            this.classActive = ''
            console.log('FALSE')
          }
        });
      } 
    },
    myFunction: function() {
      let h = window.outerWidth;
      let txt = h;
      this.text = txt;
      if (this.text == 1400 || this.text > 1401) {
        this.NewWidgetHeight = '669px!important'
      } else if(this.text < 1400  && this.text > 1199) {
        this.NewWidgetHeight = '555px!important'
      } else if(this.text > 990 && this.text < 1199) {
        this.NewWidgetHeight = '492px!important'
      }
    },
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/202?randomadd=" +
          new Date().getTime()
      )
      .then((response) => (this.wphome = response.data));
  },
  computed: {
    filterReport: function() {
      if (Array.isArray(this.reports)) {
        return this.reports.filter(
          (reports_entry) =>
            reports_entry.report_title.includes("Tournament Entries") ||
            // reports_entry.report_title == 'Round 1 Draw' ||
            reports_entry.report_title.includes("Score") ||
            // reports_entry.report_title.includes("Live") ||
            reports_entry.report_title.includes("Final")
        );
      } else return this.reports; 
    },

    currentReport() {
      if (Array.isArray(this.filterReport))
        return this.filterReport[this.filterReport.length - 1].report_url;
      else return this.reports.report_url;
    },
    currentTitle() {
      if (Array.isArray(this.filterReport))
        return this.filterReport[this.filterReport.length - 1].report_title;
      else return this.reports.report_title;
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          this.config.VUE_APP_TIC_BASE +
          this.config.VUE_APP_AUS +
          "/" +
          this.tm_params.tm_params.season_code +
          "/" +
          this.tm_params.tm_params.season_code +
          "-" +
          this.course +
          "-entryw-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );
          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_TIC_BASE +
              this.config.VUE_APP_AUS +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/" + "dev" + "/cache/" + "dev" + "/" + "215S" + "/" + "215S" + "-" + "AUS7" + "-scores-" + $scope.repParams + ".json"+"?randomadd=1603894106415";
          } else {
            url =
              this.config.VUE_APP_TIC_BASE +
              this.config.VUE_APP_AUS +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              this.config.VUE_APP_TIC_BASE +
              this.config.VUE_APP_AUS +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_TIC_BASE +
            this.config.VUE_APP_AUS +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            this.config.VUE_APP_TIC_BASE +
            this.config.VUE_APP_AUS +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_TIC_BASE +
            this.config.VUE_APP_AUS +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
  },
  //  metaInfo() {
  //    //Injects Meta and Title to Head
  //    return {
  //      title: this.title.content,
  //      meta: [
  //        {
  //          name: this.description.name,
  //          content: this.description.content
  //        }
  //      ]
  //    };
  //  },
  created() {
    this.myFunction();
    window.addEventListener('resize', this.myFunction);
    setInterval(
      console.log('In Interval 270'),
      this.updateSize, 12000,
      console.log('In Interval 272')
    );
    this.reportListText();
    apiCall 
      .tmParamsBase()
      .then(({ data }) => {
        this.tm_params = data;
        this.reports = data.reports.reports_entry;
        this.season = data.tm_params.season_code;
        this.course = data.code;
        this.course_code = data.course_code;
        this.tournDates = data.tourn_dates;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>
<style scoped>
::v-deep .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: transparent;
}
::v-deep .tabone {
  width: 27%;
}
::v-deep .tabtwo {
  width: 40%;
}
::v-deep .tabthree {
  width: 33%;
}
.homeHeight {
  height: 669px;
}
.HomehomeReport-list {
  border: 1px solid #E6E6E6;
}
::v-deep li.nav-item.tabsStyle {
  /* width: 33%; */
}
::v-deep li.nav-item.tabsStyle > a{
  /* padding-left: 13px!important;
  padding-right: 10px!important; */
}
::v-deep .nav-tabs .nav-link:hover {
  cursor: default;
  background-color: #dfe1e6;
  border-bottom-color: transparent;
  border-radius: 0px 0px 0px 0px !important;
  height: 38px;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  color: #00433e !important;
  padding-left: 18px;
  padding-right: 20px;
  text-align: center;
}
::v-deep .nav-tabs .nav-link.active {
  cursor: default;
  background-color: #fff;
  border-bottom-color: transparent;
  border-radius: 0px 0px 0px 0px !important;
  height: 38px;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #34caa9 !important;
  padding-left: 18px;
  padding-right: 20px;
  border-bottom: 0px solid #dee2e6;
  padding-top: 14px;
  padding-bottom: 35px;
  border: none!important;
}
::v-deep .nav-tabs .nav-link {
  cursor: default;
  background-color: #E6E6E6;
  color: #B8B8B8 !important;
  border-bottom-color: transparent;
  border-radius: 0px 0px 0px 0px !important;
  height: 38px;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  padding-left: 18px;
  padding-right: 20px;
  border-bottom: 0px solid #dee2e6;
  text-align: center;
  padding-top: 14px;
  padding-bottom: 35px;
  border: none!important;
}
::v-deep .nav-tabs {
    border-bottom: 0px solid #dee2e6;
}
/* ::v-deep .tabWhite {
  padding-top: 0em;
  height: 581px;
} */
.home {
  background-color: #fff;
}
.title {
  font-weight: 500;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  color: #000;
  font-size: 23pt;
  margin-top: 1em;
  line-height: 1.1;
  /* letter-spacing: 2px; */
}

.ad {
  padding: 15px 0; 
}

.bottomAdBanner {
  width: 100%;
}
.sections {
  padding: 30px 0;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
}
@media only screen and (max-height: 1024px) {
  .homeReport-list {
    /* height: auto !important; */
  }
}
@media only screen and (max-height: 430px) {
::v-deep .nav-tabs .nav-link.active {
  cursor: default;
  background-color: #fff;
  border-bottom-color: transparent;
  border-radius: 0px 0px 0px 0px !important;
  height: 38px;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: #34caa9 !important;
  padding-left: 18px;
  padding-right: 20px;
  border-bottom: 0px solid #dee2e6;
  padding-top: 15px;
  padding-bottom: 35px;
  border: none!important;
}
::v-deep .nav-tabs .nav-link {
  cursor: default;
  background-color: #E6E6E6;
  color: #B8B8B8 !important;
  border-bottom-color: transparent;
  border-radius: 0px 0px 0px 0px !important;
  height: 38px;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  padding-left: 18px;
  padding-right: 20px;
  border-bottom: 0px solid #dee2e6;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 35px;
  border: none!important;
}
}
.mobWidgetPad {
  height: 450px!important;
}


@media only screen and (max-width: 480px) {
  .bottomAdBanner{
    padding: 15px 0;
  }
}

@media only screen and (max-width: 419px) {
  ::v-deep .nav-tabs .nav-link.active {
    cursor: default;
    background-color: #fff;
    border-bottom-color: transparent;
    border-radius: 0px 0px 0px 0px !important;
    height: 38px;
    font-family: "proxima-nova, sans-serif", sans-serif !important;
    font-weight: 700;
    font-style: normal;
    font-size: 13px;
    color: #34caa9 !important;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 0px solid #dee2e6;
    border: none!important;
  }
  ::v-deep .nav-tabs .nav-link {
    cursor: default;
    background-color: #E6E6E6;
    color: #B8B8B8 !important;
    border-bottom-color: transparent;
    border-radius: 0px 0px 0px 0px !important;
    height: 38px;
    font-family: "proxima-nova, sans-serif", sans-serif !important;
    font-weight: 700;
    font-style: normal;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 0px solid #dee2e6;
    text-align: center;
    border: none!important;
  }
}
</style>
